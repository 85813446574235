import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Container } from "react-bootstrap";
import { Seo } from "../components/Seo";
import { useLocation } from "@reach/router"
import {PortableText} from '@portabletext/react'

export default function ({ data:{privacy:{bannerTitle, _rawPrivacyContent:json}} }) {
    const cnt =json;
    const myPortableTextComponents = {
        block: {
          normal: ({children}) => <p className="terms-paragraph">{children}</p>,
          h2: ({children}) => <h2 className="heading2">{children}</h2>,
        },

        list: {
            // Ex. 1: customizing common list types
            bullet: ({children}) => { return <ul className="ul-list">{children}</ul>},
            number: ({children}) => <ol className="ol-list">{children}</ol>,
        
            // Ex. 2: rendering custom lists
            checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
          },

          listItem: {
            // Ex. 1: customizing common list types
            bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='terms-paragraph'>{children}</p> : children}</li>,
            bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='terms-paragraph'>{children}</p> : children}</li>,
        
            // Ex. 2: rendering custom list items
          },
      
        marks: {
          link: ({children, value}) => {
            const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
            return (
              <a href={value.href} rel={rel}>
                {children}
              </a>
            )
          },
        },
    }
    
    const pathLocation = useLocation();
    const pageUrl = pathLocation.href;

    return <Layout>
        <Seo 
            title="Privacy Policy | Data Security Policy | Sogody" 
            description="GDPR compliant, reliable, and secure. Sogody maintains strict data security protocols for all clients. Learn more about our commitment to data protection."
            url={pageUrl}
        />
        <div className="terms-banner">
            <Container> 
                <p className="banner-title">{bannerTitle}</p>
            </Container>
        </div>
        <div className="terms-cnt"> 
        <Container className="up-container"> 
            <div className="terms-content">
                <PortableText value={cnt} components={myPortableTextComponents}/>
            </div>
        </Container>
        </div>
    </Layout>
}

export const query = graphql`
query GetPrivacy{
    privacy:  sanityPrivacyAndDataSecurityPolicies {
        _rawPrivacyContent
        bannerTitle
      }
}
`


